import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

function BestBreweries({ data }) {
  const stateData = data.file.childDataJson.usstates.sort();

  return (
    <Layout>
      <SEO
        title="Lists of the Best Breweries | Finding Beer"
        keywords={[`breweries`, `top breweries`, `lists`, `finding.beer`]}
      />
      <section className="bg-white border-b py-8 mt-20 sm:p-20 px-4">
        <div className="container max-w-5xl mx-auto m-8">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Best Brewery Lists
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div class="flex justify-center mx-auto">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm hover:bg-slate-100 block"
              href="/best-breweries/list/united-states"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/us-flags_sm.jpg`}
                alt=""
              />

              <div class="p-6 text-center">
                <h5 class="text-gray-900 text-xl font-medium mb-2">
                  Top Breweries By U.S. State
                </h5>
              </div>
            </a>
          </div>

          <div class="flex justify-center mx-auto">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm hover:bg-slate-100 block"
              href="/best-breweries/list/country"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/world-flags_sm.jpg`}
                alt=""
              />

              <div class="p-6 text-center">
                <h5 class="text-gray-900 text-xl font-medium mb-2">
                  Top Breweries By Country
                </h5>
              </div>
            </a>
          </div>


          <div class="flex justify-center mx-auto">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm hover:bg-slate-100 block"
              href="/best-breweries/list/metros"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/metros_sm.jpg`}
                alt=""
              />

              <div class="p-6 text-center">
                <h5 class="text-gray-900 text-xl font-medium mb-2">
                  Top Breweries By US Metro Area
                </h5>
              </div>
            </a>
          </div>

          <div class="flex justify-center mx-auto">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm hover:bg-slate-100 block"
              href="/best-breweries/list/international-metros"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/places/sm/Portugal.jpg`}
                alt=""
              />

              <div class="p-6 text-center">
                <h5 class="text-gray-900 text-xl font-medium mb-2">
                  Top Breweries By International Metro Area
                </h5>
              </div>
            </a>
          </div>

        </div>
      </section>

      <section className="bg-white border-b py-8 sm:p-20 px-4">
        <div className="container max-w-5xl mx-auto m-8">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Other Brewery Lists
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div class="flex justify-center mx-auto">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm hover:bg-slate-100 block"
              href="/best-breweries/list/australia"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/places/sm/Australia.jpg`}
                alt=""
              />

              <div class="p-6 text-center">
                <h5 class="text-gray-900 text-xl font-medium mb-2">
                  Top Breweries By Australian Province
                </h5>
              </div>
            </a>
          </div>

          <div class="flex justify-center mx-auto">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm hover:bg-slate-100 block"
              href="/best-breweries/list/canada"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/places/sm/Canada.jpg`}
                alt=""
              />

              <div class="p-6 text-center">
                <h5 class="text-gray-900 text-xl font-medium mb-2">
                  Top Breweries By Canadian Province
                </h5>
              </div>
            </a>
          </div>

          <div class="flex justify-center mx-auto">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm hover:bg-slate-100 block"
              href="/best-new-breweries/united-states"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/places/sm/United%20States.jpg`}
                alt=""
              />

              <div class="p-6 text-center">
                <h5 class="text-gray-900 text-xl font-medium mb-2">
                  Top New Breweries In the United States
                </h5>
              </div>
            </a>
          </div>

          <div class="flex justify-center mx-auto">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm hover:bg-slate-100 block"
              href="/best-new-breweries/international"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/places/sm/International.jpg`}
                alt=""
              />

              <div class="p-6 text-center">
                <h5 class="text-gray-900 text-xl font-medium mb-2">
                  Top New Breweries Internationally
                </h5>
              </div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default BestBreweries;

export const query = graphql`
  query {
    file(name: { eq: "allPlaces" }) {
      childDataJson {
        usstates
      }
    }
  }
`;
